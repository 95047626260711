import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { coerceBoolean } from '@util/functions/objects';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { KurzIconType } from '../../../custom/custom-configuration-modules/custom-icon-types-mapping.module';
import { KurzIconSize } from '../icon/icon.component';
import { ColorThemableComponent } from '../shared/color-theme/theme.enum';
import { getTextLengthOfElement } from '@util/functions/elements';

export interface KurzButtonIconObject {
  borderLeft?: KurzIconType;
  labelLeft?: KurzIconType;
  labelRight?: KurzIconType;
  borderRight?: KurzIconType;
}

export enum KurzButtonSize {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements ColorThemableComponent, AfterViewInit, OnDestroy {

  @HostBinding('class')
  private get hostClasses(): string[] {
    const arr: string[] = ['app-button', this._sizeClass];
    if (this.disabled) {
      arr.push('app-button-disabled');
    }
    return arr;
  }

  private _size: KurzButtonSize = KurzButtonSize.MEDIUM;
  private _sizeClass = 'app-button-medium';

  private _buttonIconObject: KurzButtonIconObject = {};

  private _showActivityBehaviorSubject = new BehaviorSubject<boolean>(false);

  iconSize: KurzIconSize = KurzIconSize.MEDIUM;

  private resizeObs = new ResizeObserver(() => {
    let isTooLong = false;
    if (this.elementRef.nativeElement) {
      isTooLong = this.isLabelTooLong();
    }
    if (isTooLong !== this.labelTooLong$.value) {
      this.labelTooLong$.next(isTooLong);
    }
  });

  labelTooLong$ = new BehaviorSubject<boolean>(false);

  constructor(private elementRef: ElementRef, private utilCustomCSSPropertyService: UtilCustomCSSPropertyService) {}

  ngAfterViewInit(): void {
    const host = this.elementRef.nativeElement as HTMLElement;
    this.resizeObs.observe(host);
  }

  ngOnDestroy(): void {
    this.resizeObs.disconnect();
  }

  @Input()
  label: string;

  @Input()
  set buttonIconObject(value: KurzButtonIconObject) {
    this._buttonIconObject = Object.assign((this.buttonIconObject || {}), value);
  }

  get buttonIconObject(): KurzButtonIconObject {
    return this._buttonIconObject;
  }

  buttonClasses$ = new BehaviorSubject<string[]>([]);

  @Input()
  set buttonClasses(value: string[]) {

    if (typeof value === 'string') {
      value = (value as string).split(' ').filter(cl => !!cl);
    }

    this.buttonClasses$.next(value);
  }

  /**
   * exists out of convenience to easily set the icon type for the left place next to the label
   */
  @Input()
  set iconType(value: KurzIconType) {
    this.buttonIconObject = Object.assign((this.buttonIconObject || {}), ({labelLeft: value} as KurzButtonIconObject));
  }

  /**
   * exists out of convenience to easily set the icon type for the right place next to the label
   */
  @Input()
  set secondIconType(value: KurzIconType) {
    this.buttonIconObject = Object.assign((this.buttonIconObject || {}), ({labelRight: value} as KurzButtonIconObject));
  }

  @Input()
  set borderLeftIconType(value: KurzIconType) {
    this.buttonIconObject = Object.assign((this.buttonIconObject || {}), ({borderLeft: value} as KurzButtonIconObject));
  }

  @Input()
  set borderRightIconType(value: KurzIconType) {
    this.buttonIconObject = Object.assign((this.buttonIconObject || {}), ({borderRight: value} as KurzButtonIconObject));
  }

  @Input()
  set labelLeftIconType(value: KurzIconType) {
    this.buttonIconObject = Object.assign((this.buttonIconObject || {}), ({labelLeft: value} as KurzButtonIconObject));
  }

  @Input()
  set labelRightIconType(value: KurzIconType) {
    this.buttonIconObject = Object.assign((this.buttonIconObject || {}), ({labelRight: value} as KurzButtonIconObject));
  }

  get buttonSize(): KurzButtonSize {
    return this._size;
  }

  @Input()
  set buttonSize(value: KurzButtonSize) {

    this._sizeClass = 'app-button-medium';
    this.iconSize = KurzIconSize.MEDIUM;

    switch(value) {
      case KurzButtonSize.EXTRA_SMALL: {
        this._sizeClass = 'app-button-extra-small';
        this.iconSize = KurzIconSize.EXTRA_SMALL;
      } break;
      case KurzButtonSize.SMALL: {
        this._sizeClass = 'app-button-small';
        this.iconSize = KurzIconSize.SMALL;
      } break;
      case KurzButtonSize.LARGE: {
        this._sizeClass = 'app-button-large';
        this.iconSize = KurzIconSize.LARGE;
      } break;
      case KurzButtonSize.EXTRA_LARGE: {
        this._sizeClass = 'app-button-extra-large';
        this.iconSize = KurzIconSize.EXTRA_LARGE;
      } break;
    }
    this._size = value;
  }

  @Input()
  set flex(value: boolean) {
    const res = coerceBoolean(value);
    this.utilCustomCSSPropertyService.setValue('--app-button-width', (res ? 'unset' : 'max-content'), this.elementRef.nativeElement);
  }

  private _disabled: boolean;

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public set disabled(value: boolean) {
    const res = coerceBoolean(value);
    this._disabled = res;
    const host = this.elementRef.nativeElement as HTMLElement;
    const button = host?.querySelector('button') as HTMLButtonElement;
    if (button && res) {
      button.setAttribute('disabled', 'true');
    }
    if (button && !res) {
      button.removeAttribute('disabled');
    }
  }

  @Input()
  superscript: string;

  @Input()
  tabindex: string = '0';

  get showActivity$(): Observable<boolean> {
    return this._showActivityBehaviorSubject.asObservable();
  }

  get showActivity(): boolean {
    return this._showActivityBehaviorSubject.value;
  }

  @Input()
  set showActivity(value: boolean) {
    value = coerceBoolean(value);
    this._showActivityBehaviorSubject.next(value);
  }

  /**
   * returns true if the label is too long for the button (label is rendered in one line) and hence the button would be rendered not as indended
   * how algorithm works:
   * it uses the browser feature that its html engine automatically breaks the text into multiple lines if the text is too long for its container element
   * 1. we make sure that there are no automatic text shortening features active (= text-overflow needs to be normalized)
   * 2. we save the width of the text containing element before we...
   * 3. we make sure that the texts would break if it was too long.
   * 4. save the width again after doing this (also reset manipulated styling as it was before)
   * Compare both widths => it is too long if the width taken before (2.) is bigger than the one after (4.)
   */
  private isLabelTooLong(): boolean {

    let tooLong = false;
    const host = this.elementRef.nativeElement as HTMLElement;

    // getting the needed elements and css
    const container = host.querySelector('button');
    const labelSpan = container.querySelector('.label-span') as HTMLSpanElement;

    const labelSpanCompCSS = window.getComputedStyle(labelSpan);

    // 1.
    const labelSpanTextOverflowTmp = labelSpanCompCSS.textOverflow;
    labelSpan.style.setProperty('text-overflow', 'initial');

    const labelSpanOverflowTmp = labelSpanCompCSS.overflowX;
    labelSpan.style.setProperty('overflow-x', 'initial');

    // 2.
    const labelSpanWidthBefore = labelSpan.getBoundingClientRect().width;

    // 3.
    const conDisplayTmp = container.style.display;
    container.style.setProperty('display', 'flex');

    const labelSpanWithSpacerTmp = labelSpanCompCSS.whiteSpace;
    labelSpan.style.setProperty('white-space', 'initial');

    // 4.
    const labelSpanWidthAfter = labelSpan.getBoundingClientRect().width;

    // reset manipulation
    container.style.setProperty('display', conDisplayTmp);
    labelSpan.style.setProperty('white-space', labelSpanWithSpacerTmp);
    labelSpan.style.setProperty('text-overflow', labelSpanTextOverflowTmp);
    labelSpan.style.setProperty('overflow-x', labelSpanOverflowTmp);


    // compare width before and after manipulation to figure it out if text is to long
    tooLong = labelSpanWidthBefore > labelSpanWidthAfter;

    return tooLong;
  }

}
