import { Component, Input, OnInit } from '@angular/core';
import { coerceBoolean } from '@util/functions/objects';
import { BehaviorSubject, Observable } from 'rxjs';
import { ColorThemableComponent } from '../shared/color-theme/theme.enum';

@Component({
  selector: 'app-activity-indicator',
  templateUrl: './activity-indicator.component.html',
  styleUrls: ['./activity-indicator.component.scss']
})
export class ActivityIndicatorComponent implements ColorThemableComponent, OnInit {

  private _showIndicatorBehaviorSubject = new BehaviorSubject<boolean>(false);

  get showActivity$(): Observable<boolean> {
    return this._showIndicatorBehaviorSubject.asObservable();
  }

  @Input()
  set showActivity(value: boolean) {
    value = coerceBoolean(value);
    this._showIndicatorBehaviorSubject.next(value);
  }

  get showActivity(): boolean {
    return this._showIndicatorBehaviorSubject.value;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
