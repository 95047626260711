<button [attr.tabindex]="tabindex" [ngClass]="buttonClasses$ | async" [kurzTooltip]="(labelTooLong$ | async) ? label : ''">
  <ng-template #dummyItem><div></div></ng-template>

  <ng-container *ngIf="buttonIconObject.borderLeft; else dummyItem">
    <app-icon [iconType]="buttonIconObject.borderLeft" [iconSize]="iconSize"></app-icon>
  </ng-container>


  <div class="icon-label-container">
    <app-icon *ngIf="buttonIconObject.labelLeft" [iconType]="buttonIconObject.labelLeft" [iconSize]="iconSize"></app-icon>
    <span class="label-span">{{label}}</span>
    <app-icon *ngIf="buttonIconObject.labelRight" [iconType]="buttonIconObject.labelRight" [iconSize]="iconSize"></app-icon>
  </div>


  <ng-container *ngIf="buttonIconObject.borderRight; else dummyItem">
    <app-icon class="pos-left-10" [iconType]="buttonIconObject.borderRight" [iconSize]="iconSize"></app-icon>
  </ng-container>

</button>

<span *ngIf="superscript" [ngClass]="['app-button-superscript', superscript?.length === 1 ? 'app-button-superscript-single-digit' : '']">{{ superscript }}</span>

<ng-container *ngIf="showActivity$ | async">
  <app-activity-indicator [showActivity]="true"></app-activity-indicator>
</ng-container>
